export const geometryColors = {
  reference: "#FB8A00",
  qm: "#f854c4",
};

// Most distinct 12 colors with maximum saturation
export const taskInputMainColors = [
  "#ff4000",
  "#ffbf00",
  "#bfff00",
  "#40ff00",
  "#00ff40",
  "#00ffbf",
  "#00bfff",
  "#0040ff",
  "#4000ff",
  "#bf00ff",
  "#ff00bf",
  "#ff0040",
];

export const taskInputDefaultColor = taskInputMainColors[10];

export const distinctColors = (
  num: number,
  firstColor: string = taskInputMainColors[10],
): string[] => {
  const hues = [];
  const saturation = 100;
  const lightness = 50;

  // Parse the hue of the first color (if provided)
  let initialHue = 0;
  if (firstColor) {
    const colorRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
    const match = firstColor.match(colorRegex);
    if (match) {
      const [_, r, g, b] = match.map((hex) => parseInt(hex, 16));
      const hsv = rgbToHsv(r / 255, g / 255, b / 255);
      initialHue = hsv[0];
    }
  }

  // Calculate the step size for even distribution of hues
  const step = 360 / num;

  // Generate distinct hues
  for (let i = 0; i < num; i++) {
    const hue = (initialHue + i * step) % 360;
    hues.push(hue);
  }

  // Convert HSL values to RGB
  const colors = hues.map((hue) => hslToRgb(hue, saturation, lightness));

  // Convert RGB values to hex
  return colors.map((rgb) => rgbToHex(rgb[0], rgb[1], rgb[2]));
};

// Helper function to convert RGB to hex
const rgbToHex = (r: number, g: number, b: number): string => {
  return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
};

// Helper function to convert RGB to HSV
const rgbToHsv = (
  r: number,
  g: number,
  b: number,
): [number, number, number] => {
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;
  let h: number, s: number;

  const v = max;

  if (max !== 0) {
    s = (delta / max) * 100;
  } else {
    // r = g = b = 0
    s = 0;
    h = -1;
    return [h, s, v];
  }

  if (r === max) {
    h = (g - b) / delta + (g < b ? 6 : 0);
  } else if (g === max) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  return [h, s, v];
};

// Helper function to convert HSL to RGB
const hslToRgb = (
  h: number,
  s: number,
  l: number,
): [number, number, number] => {
  const hue2rgb = (p: number, q: number, t: number) => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };

  h /= 360;
  s /= 100;
  l /= 100;

  let r: number, g: number, b: number;

  if (s === 0) {
    r = g = b = l;
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};
