import { Route, RouteProps } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import keycloak from "keycloak";
import axios from "axios";
import { UserRoleModel } from "models/users.model";
import { checkIfUserHaveRole } from "helpers/keycloakHelpers";
import NoAccessPage from "routes/NoAccessPage";
import qs from "qs";
import { SendFilterComparisonModel } from "models/filter.model";
import { SortingRequestModel } from "models/exploration.model";

const backendURL =
  process.env.REACT_APP_BACKEND_URL ??
  alert("Config value 'backend url' is undefined");

// A method for making an auth'd request to the backend
export const APIFetchAxios = (
  endpoint: string,
  params?: any,
  withCredentials: boolean = false,
) => {
  // Serialize each query object as a JSON string
  if (Array.isArray(params?.query)) {
    params.query = params.query.map((queryObj: SendFilterComparisonModel) =>
      JSON.stringify(queryObj),
    );
  }
  if (Array.isArray(params?.sort)) {
    params.sort = params.sort.map((queryObj: SortingRequestModel) =>
      JSON.stringify(queryObj),
    );
  }

  return axios.get(backendURL + endpoint, {
    headers: {
      Authorization: "Bearer " + keycloak.token,
    },
    params: params || {},
    withCredentials: withCredentials,
    paramsSerializer: (params) => {
      return qs.stringify(params, {
        arrayFormat: "repeat",
        encodeValuesOnly: true, // to ensure characters like {} are properly encoded
      });
    },
  });
};

export const APIDeleteAxios = (endpoint: string) => {
  return axios.delete(backendURL + endpoint, {
    headers: {
      Authorization: "Bearer " + keycloak.token,
      "Content-Type": "application/json",
      // responseType: "stream",
    },
  });
};

export const APIPostWithBodyAxios = (endpoint: string, body: any) => {
  return axios.post(backendURL + endpoint, JSON.stringify(body), {
    headers: {
      Authorization: "Bearer " + keycloak.token,
      "Content-Type": "application/json",
      // responseType: "stream",
    },
  });
};

export const APIPutWithBodyAxios = (endpoint: string, body: any) => {
  return axios.put(backendURL + endpoint, JSON.stringify(body), {
    headers: {
      Authorization: "Bearer " + keycloak.token,
      "Content-Type": "application/json",
      // responseType: "stream",
    },
  });
};

export const APIPatchWithBodyAxios = (endpoint: string, body: any) => {
  return axios.patch(backendURL + endpoint, JSON.stringify(body), {
    headers: {
      Authorization: "Bearer " + keycloak.token,
      "Content-Type": "application/json",
    },
  });
};

// This is a route which will require auth.
// If they are logged in they will see the route as normal,
// if not, they will be sent to the login page
interface PrivateRouteProps extends RouteProps {
  roles: UserRoleModel[];
  isPublic?: boolean;
}

export function PrivateRoute({
  roles,
  isPublic,
  ...routeProps
}: PrivateRouteProps) {
  const { keycloak } = useKeycloak();

  const authenticationRedirect = () => {
    // If the route is public, we don't need to check for auth
    if (isPublic) {
      return <Route {...routeProps} />;
    }

    const isAuthenticated = keycloak?.authenticated;
    // Check if the user is authenticated
    if (isAuthenticated) {
      // Check if the user has the required roles

      const isAuthorizedToViewRoute = checkIfUserHaveRole(keycloak, roles);
      // If the user doesn't have the required roles, show an unauthorized page
      if (!isAuthorizedToViewRoute) {
        return <NoAccessPage />;
      }

      // If the user is authenticated and has the required roles, show the route
      return <Route {...routeProps} />;
    } else {
      alert("Session expired. Redirecting to login page!");
      return <div></div>;
    }
  };

  return authenticationRedirect();
}
