interface Props {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  strokeWidth?: number;
}

const BoundingBox2DCenterPointSVG = ({
  x,
  y,
  width,
  height,
  color,
  strokeWidth = 2,
}: Props) => {
  return (
    <rect
      x={x - width / 2}
      y={y - height / 2}
      width={width}
      height={height}
      stroke={color}
      strokeWidth={strokeWidth}
      fill="none"
    />
  );
};

export default BoundingBox2DCenterPointSVG;
