import { snakeCaseToText } from "components/utilFunctions";
import getSubsetName from "helpers/functions/getSubsetName";
import _ from "lodash";
import { DatasetModel } from "models/dataset.model";
import {
  MediaObjectRawModel,
  MediaObjectModel,
} from "models/exploration.model";
import { geometryColors } from "helpers/colors";

export const convertMediaObjectsRawModelToMediaObjectModel = (
  mediaObjects: MediaObjectRawModel[],
  subSets: DatasetModel[],
) => {
  let newBBs: MediaObjectModel[] = [];
  _.map(mediaObjects, (mediaObject) => {
    newBBs = [
      ...newBBs,
      convertMediaObjectRawModelResponseToMediaObjectModel(
        mediaObject,
        subSets,
      ),
    ];
  });
  return newBBs;
};

export const convertMediaObjectRawModelResponseToMediaObjectModel = (
  mediaObject: MediaObjectRawModel,
  subSets: DatasetModel[],
) => {
  const objectCategoryName = snakeCaseToText(
    getSubsetName(mediaObject?.object_category, subSets),
  );
  if (mediaObject?.source === "REFERENCE") {
    return {
      ...mediaObject,
      object_category_name: objectCategoryName,
      color: `border-[${geometryColors.reference}]`,
    };
  } else {
    return {
      ...mediaObject,
      object_category_name: objectCategoryName,
      color: `border-[${geometryColors.qm}]`,
    };
  }
};
