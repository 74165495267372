import { UiSchema } from "@rjsf/utils";

const baseUiSchema: UiSchema = {
  schemaVersion: {
    "ui:widget": "hidden",
  },
  "ui:submitButtonOptions": {
    norender: true,
  },
  question: {
    "ui:showFullBorder": true,
    Question: {
      "ui:title": "",
      open: { "ui:widget": "hidden" },
    },
  },
  inputType: {
    "ui:showFullBorder": true,
    BoundingBox2DPainter: {
      videoPlayerSettings: {
        // VideoPlayer Settings in BoundingBox2DPainter are deprecated in favour of the dedicated VideoRenderer settings so we hide it here
        "ui:widget": "hidden",
      },
      displayerType: {
        "ui:options": {
          disabled: true,
        },
      },
      interpolation: {
        "ui:widget": "hidden",
      },
      maxShapes: {
        "ui:widget": "hidden",
      },
      settings: {
        "ui:widget": "hidden",
      },
      minZoomKeypoint: {
        "ui:widget": "hidden",
      },
      minZoomTimeout: {
        "ui:widget": "hidden",
      },
      forceZoomKeypoint: {
        "ui:widget": "hidden",
      },
      scaleKeypointSize: {
        "ui:widget": "hidden",
      },
      boundingBoxProps: {
        opacityRules: {
          "ui:widget": "hidden",
        },
      },
      geometryMarginWidth: {
        "ui:widget": "hidden",
      },
      mousePositionZoom: {
        "ui:widget": "hidden",
      },
      mouseWheelZoom: {
        "ui:widget": "hidden",
      },
      disableDrawing: {
        "ui:widget": "hidden",
      },
    },
    Viewer3D: {
      annotationType: {
        "ui:options": {
          disabled: true,
        },
      },
    },
    VideoRenderer: {
      annotationType: {
        "ui:options": {
          disabled: true,
        },
      },
    },
  },
  toolbars: {
    "ui:options": {
      addable: false,
    },
    items: {
      type: {
        "ui:options": {
          disabled: true,
        },
      },
      settings: {
        selectOnlyCustomMarks: {
          "ui:widget": "hidden",
        },
        withOptions: {
          "ui:widget": "hidden",
        },
      },
    },
  },
  annotationType: {
    "ui:showFullBorder": true,
  },
  uiSettings: {
    "ui:showFullBorder": true,
    Settings: {
      submitButton: {
        "ui:widget": "hidden",
      },
      keyBindings: {
        "ui:widget": "hidden",
      },
      isFeedBackButton: {
        "ui:widget": "hidden",
      },
      areInstructionsMandatory: {
        "ui:widget": "hidden",
      },
    },
  },
  translatables: { "ui:widget": "hidden" },
};

export default baseUiSchema;
