import { useKeycloak } from "@react-keycloak/web";
import { UserRoleModel } from "models/users.model";

function userCanAccessFeature(featureFlag: string) {
  const { keycloak } = useKeycloak();
  return (
    keycloak.hasResourceRole(featureFlag) ||
    keycloak.hasRealmRole(UserRoleModel.Internal)
  );
}

interface Props {
  featureFlag: string;
  children: any;
}

export const FlaggedFeature = ({ featureFlag, children }: Props) => {
  if (userCanAccessFeature(featureFlag)) {
    return children;
  }
};

export const flagArrayElementFeature = (featureFlag: string, element: any) => {
  return userCanAccessFeature(featureFlag) ? [element] : [];
};
