import { useEffect, useRef, useState } from "react";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import _ from "lodash";
import { MediaModel, MediaObjectModel } from "models/exploration.model";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as ArrowLeftIcon } from "assets/arrow_small_left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/arrow_small_right.svg";
import { ReactComponent as Checkmark } from "assets/check_mark.svg";
import Loading from "components/UtilComponents/Loading";
import { useAppSelector } from "store/hooks";
import getAttributeFromAttributesMeta from "helpers/functions/getAttributeFromAttributesMeta";
import {
  fitTypeEnum,
  getAnnotatableUrl,
  getGeometryColorBySource,
  memoizedFindAppropriateThumbnail,
  transformString,
} from "components/utilFunctions";
import MediaIcon from "assets/image.svg";
import { isAttributeAnnotationAttribute } from "helpers/functions/attributes/attributesHelpers";
import { Mediatype } from "models/dataset.model";
import PointCloudIcon from "assets/point_cloud.svg";
import MediaObjectOverlay from "components/GeometryOverlays/mediaObjectOverlay";

interface Props {
  media: MediaModel | undefined;
  currentMediaIndex?: number;
  onMediaClick: () => void;
  mediaObjects: MediaObjectModel[];
  currentMediaObjectIndex?: number;
  setCurrentMediaObjectIndex?: (newIndex: number) => void;
  explorationMediaObjectsLoading?: boolean;
  onMediaObjectHover: (hoveredMediaObject: MediaObjectModel) => void;
  onMediaObjectClick: (clickedMediaObjectID: string) => void;
  selectedItemID: string;
  handleOnReachEnd?: () => void;
  renderAllTooltips?: boolean;
}

const slidesPerView = 10;
const indexHeight = 20;

const ThumbnailsCarousel = ({
  media,
  currentMediaIndex,
  onMediaClick,
  mediaObjects,
  currentMediaObjectIndex,
  setCurrentMediaObjectIndex,
  explorationMediaObjectsLoading,
  onMediaObjectHover,
  onMediaObjectClick,
  selectedItemID,
  handleOnReachEnd,
  renderAllTooltips,
}: Props) => {
  const attributesMeta = useAppSelector(
    (state) => state.datasetSlice.attributes,
  );

  const [swiper, setSwiper] = useState<SwiperCore>();
  const [reachedBeginning, setReachedBeginning] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);

  // Set reachedEnd and reachedBeginning on render and if slides change
  useEffect(() => {
    if (swiper && swiper?.slides.length === mediaObjects?.length) {
      if (swiper.isBeginning) {
        setReachedBeginning(true);
      } else {
        setReachedBeginning(false);
      }
      if (swiper.isEnd) {
        setReachedEnd(true);
      } else {
        setReachedEnd(false);
      }
    }
  }, [swiper?.slides]);

  // Slide to the item when selectedItemID is changed
  useEffect(() => {
    let indexOfNewMediaObject = currentMediaObjectIndex;
    if (currentMediaObjectIndex) {
      indexOfNewMediaObject = _.findIndex(
        mediaObjects,
        (mediaObject) => mediaObject?.id === selectedItemID,
      );
    }
    if (indexOfNewMediaObject && indexOfNewMediaObject >= 0) {
      swiper?.slideTo(indexOfNewMediaObject);
    }
  }, [selectedItemID]);

  // loads new objects when end of loaded objects is reached
  useEffect(() => {
    if (
      // If the current object is found in the list of objects
      !_.isUndefined(currentMediaObjectIndex) &&
      currentMediaObjectIndex !== -1 &&
      !_.isUndefined(setCurrentMediaObjectIndex) &&
      // If the current object is the last object in the list
      swiper?.isEnd &&
      reachedEnd
    ) {
      // handleOnReachEnd && handleOnReachEnd();
      setReachedEnd(false);
    }
  }, [reachedEnd]);

  const selectMediaWithTooltipOrNot = (
    mediaObject: MediaObjectModel,
    index: number,
  ) => {
    if (renderAllTooltips) {
      return renderMediaWithTooltip(mediaObject);
    } else {
      if (index === currentMediaObjectIndex) {
        return renderMediaWithTooltip(mediaObject);
      } else {
        return renderMedia(mediaObject);
      }
    }
  };

  const renderMediaWithTooltip = (mediaObject: MediaObjectModel) => {
    const toolTipClass = `bg-white border-2 ${mediaObject?.color}`;
    return (
      <Tooltip
        title={renderTooltipTitle(mediaObject)}
        classes={{
          tooltip: toolTipClass,
        }}
        arrow
        placement="top"
        enterDelay={300}
      >
        {renderMedia(mediaObject)}
      </Tooltip>
    );
  };

  const renderMedia = (mediaObject: MediaObjectModel) => {
    if (
      mediaObject.media_object_type === "cuboid_center_point" ||
      mediaObject.media_object_type === "point3d_xyz" ||
      mediaObject.media_object_type === "point3d_xyz_aggregation"
    ) {
      return (
        <img
          src={PointCloudIcon}
          alt={mediaObject.back_reference}
          onMouseOver={() => onMediaObjectHover(mediaObject)}
          onClick={() => onMediaObjectClick(mediaObject?.id)}
        />
      );
    }
    return (
      <div>
        <MediaObjectOverlay
          mediaObject={mediaObject}
          maxSize={[70, 70]}
          className="w-auto h-[48px] mx-auto rounded-[4px] cursor-pointer"
          onMouseOver={() => onMediaObjectHover(mediaObject)}
          onClick={() => onMediaObjectClick(mediaObject?.id)}
          color={getGeometryColorBySource(mediaObject)}
        />
      </div>
    );
  };

  const renderTooltipTitle = (mediaObject: MediaObjectModel) => {
    const numberOfAttributes = _.filter(mediaObject?.attributes, (att) => {
      const attributeMeta = getAttributeFromAttributesMeta(
        attributesMeta,
        att?.metadata_id,
      );
      if (!attributeMeta) return false;
      return isAttributeAnnotationAttribute(attributeMeta?.attribute_group);
    });
    return (
      <div className="p-2 text-sm bg-white text-black">
        <div>Source: {transformString(mediaObject?.source)}</div>
        <div>Category: {mediaObject?.object_category_name}</div>
        <div>No. attributes: {numberOfAttributes?.length}</div>
      </div>
    );
  };

  const renderIndex = (index: number, objectID: string) => {
    return (
      <div
        className={`w-full h-[${indexHeight}px] mt-[3px] rounded-full justify-center text-center text-sm 
        ${objectID === selectedItemID ? "bg-paletteOrange" : "bg-white"} 
        ${objectID === selectedItemID ? "text-white" : "text-black"}`}
      >
        {index + 1}
      </div>
    );
  };

  const mediaSelected = (mediaID: string) => {
    return (
      <div className="flex justify-center mt-[3px]">
        <div
          className={`relative left-[10px] top-[1px] rounded-full 
          ${mediaID === selectedItemID ? "bg-white" : "bg-black"}`}
          style={{ width: indexHeight - 2, height: indexHeight - 2 }}
        ></div>
        <Checkmark
          width={indexHeight}
          height={indexHeight}
          className={`relative right-[10px] ml-[1px] 
          ${mediaID === selectedItemID ? "text-paletteOrange" : "text-white"}`}
        />
      </div>
    );
  };

  const renderMediaIndex = () => {
    if (_.isUndefined(currentMediaIndex)) {
      return null;
    }

    return (
      <div
        className={`h-[${indexHeight}px] px-1 mt-[3px] rounded-lg
          justify-center text-center text-sm bg-paletteOrange text-white
       `}
      >
        {currentMediaIndex + 1}
      </div>
    );
  };

  const generate_thumbnail = () => {
    let thumbnail;

    if (media?.media_type === Mediatype.image) {
      thumbnail = getAnnotatableUrl(media, 85, 60, fitTypeEnum.FIT_IN);
    } else if (media?.media_type === Mediatype.point_cloud) {
      thumbnail = PointCloudIcon;
    } else if (!media?.thumbnails) {
      thumbnail = MediaIcon;
    } else {
      thumbnail =
        media?.thumbnails?.[
          memoizedFindAppropriateThumbnail(divRef, media?.thumbnails) ||
            "140x113"
        ];
    }
    return thumbnail;
  };

  return (
    <div
      className="w-full h-full rounded-[4px]
        flex items-center bg-white p-2.5"
    >
      <div ref={divRef} className="w-[102px] h-full flex flex-col items-center">
        <img
          src={generate_thumbnail()}
          alt=""
          className="w-full h-[48px] mx-auto rounded-[4px] cursor-pointer object-contain"
          data-test="detail_jump_image"
          onClick={() => {
            onMediaClick();
          }}
        />
        {!_.isUndefined(currentMediaIndex)
          ? renderMediaIndex()
          : mediaSelected(media?.id || "")}
      </div>
      <div
        className={
          "w-[1px] h-[32px] border-l-[1px] bg-paletteGray-3 relative ml-2.5"
        }
        style={{ marginBottom: indexHeight + 3 }}
      />
      <div
        className={
          "custom_prev icon-button-layer-circle h-[30px] w-[30px] ml-1 relative"
        }
        style={{ marginBottom: indexHeight + 3 }}
        onClick={() => swiper?.slidePrev()}
        data-test="carousel_previous_button"
      >
        <ArrowLeftIcon
          className={`${
            reachedBeginning ? "text-paletteGray-6" : "text-black"
          }`}
        />
      </div>
      <Swiper
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerView}
        data-test="object_carousel"
        onInit={setSwiper}
        onSlideChange={() => {
          if (swiper && !swiper.isBeginning) {
            setReachedBeginning(false);
          }
          if (swiper && !swiper.isEnd) {
            setReachedEnd(false);
          }
        }}
        onReachBeginning={() => {
          if (swiper) {
            setReachedBeginning(true);
          }
        }}
        onReachEnd={() => {
          if (swiper) {
            setReachedEnd(true);
          }
        }}
        allowTouchMove={false}
        className="w-[100%] h-full"
      >
        {_.map(mediaObjects, (mediaObject, i: number) => {
          return (
            <SwiperSlide key={i} className="w-auto h-full px-1">
              {selectMediaWithTooltipOrNot(mediaObject, i)}
              {renderIndex(i, mediaObject?.id)}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div
        className={`custom_next icon-button-layer-circle
        h-[30px] w-[30px] relative mr-[-5px]`}
        style={{ marginBottom: indexHeight + 3 }}
        onClick={() => swiper?.slideNext()}
        data-test="carousel_next_button"
      >
        <ArrowRightIcon
          className={`${reachedEnd ? "text-paletteGray-6" : "text-black"}`}
        />
      </div>
      {explorationMediaObjectsLoading === true && (
        <div>
          <Loading />
        </div>
      )}
    </div>
  );
};
export default ThumbnailsCarousel;
