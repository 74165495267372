import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchOneMediaObjectAPI } from "helpers/apis/mediaObjects";
import _ from "lodash";
import { APIFetchAxios } from "routes/Auth";
import {
  ExplorationAPIRequestModel,
  MediaObjectRawModel,
} from "models/exploration.model";
import { SendFilterModel } from "models/filter.model";
import subsetQuery from "helpers/functions/subsetQuery";

export const fetchMediaObjectsData = createAsyncThunk(
  "explorationMediaObjects/fetchMediaObjectsData",
  async (meta?: {
    runId: string;
    reqBody: ExplorationAPIRequestModel;
    subSetId: string;
    reset?: boolean;
    skipLoading?: boolean;
  }) => {
    let objReqBody = {
      ...meta?.reqBody,
      ["dataset_id"]: meta?.runId,
      presign_medias: false,
    };

    // Inject subset id as a categorical filter if "subSetId" paramter is passed
    if (meta?.subSetId !== "main_dataset") {
      const filters: SendFilterModel[] = objReqBody?.query
        ? [...objReqBody?.query]
        : [];
      objReqBody = {
        ...objReqBody,
        query: [...filters, subsetQuery(meta?.subSetId || "")],
      };
    }
    if (_.isUndefined(objReqBody?.projection)) {
      objReqBody = { ...objReqBody, projection: null };
    }

    const response = await APIFetchAxios(
      `/datasets/${meta?.runId}/mediaObjects`,
      objReqBody,
    );
    return { data: response?.data, meta: meta };
  },
);

export const fetchOneMediaObject = createAsyncThunk(
  "explorationMediaObjects/fetchOneMediaObject",
  async (meta: {
    query: { mediaObjectId: string; datasetId: string };
    options?: { replaceCurrentMediaObject: boolean };
  }) => {
    const response = await fetchOneMediaObjectAPI(
      meta?.query?.mediaObjectId,
      meta?.query?.datasetId,
    );
    return { data: response, meta: meta };
  },
);

export interface explorationMediaObjectsStateTypes {
  data: MediaObjectRawModel[];
  loading: boolean;
  error: { message: string };
  hasMore: boolean;
  skip: number;
  currentMediaObject: MediaObjectRawModel | null;
  selectedMediaObjectsIDs: string[];
  lastSelectedMediaObjectID: string | null;
}

const initialState = {
  data: [],
  loading: false,
  error: { message: "" },
  hasMore: true,
  skip: 0,
  currentMediaObject: null,
  selectedMediaObjectsIDs: [],
  lastSelectedMediaObjectID: null,
} as explorationMediaObjectsStateTypes;

export const explorationMediaObjectsSlice = createSlice({
  name: "explorationMediaObjects",
  initialState,
  reducers: {
    updateMediaObjectsExplorationHasMore: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.hasMore = action?.payload;
    },
    updateMediaObjectsExplorationSkip: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.skip = action?.payload;
    },
    setMediaObjectExplorationCurrentMediaObject: (
      state,
      action: PayloadAction<MediaObjectRawModel>,
    ) => {
      state.currentMediaObject = action?.payload;
    },
    addMediaObjectIDToSelectedMediaObjectIDs: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.selectedMediaObjectsIDs = [
        ...state.selectedMediaObjectsIDs,
        action?.payload,
      ];
      state.lastSelectedMediaObjectID = action?.payload;
    },
    removeMediaObjectIDFromSelectedMediaObjectIDs: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.selectedMediaObjectsIDs = _.filter(
        state.selectedMediaObjectsIDs,
        (id) => id !== action?.payload,
      );
      // Get the media before the deleted one
      const lastSelectedMediaObjectID =
        state.selectedMediaObjectsIDs[state.selectedMediaObjectsIDs.length - 1];
      state.lastSelectedMediaObjectID = lastSelectedMediaObjectID || null;
    },
    addMediaObjectIDsToSelectedMediaObjectIDs: (
      state,
      action: PayloadAction<{
        newIDs: string[];
        clickedID: string;
      }>,
    ) => {
      const newMediaObjectIDsSet = new Set(state.selectedMediaObjectsIDs);
      action?.payload?.newIDs.map((id) => newMediaObjectIDsSet.add(id));
      state.selectedMediaObjectsIDs = [...Array.from(newMediaObjectIDsSet)];
      state.lastSelectedMediaObjectID = action?.payload?.clickedID;
    },
    removeMediaObjectIDsFromSelectedMediaObjectIDs: (
      state,
      action: PayloadAction<{
        newIDs: string[];
      }>,
    ) => {
      const newMediaObjectIDsSet = new Set(state.selectedMediaObjectsIDs);
      action?.payload?.newIDs.map((id) => newMediaObjectIDsSet.delete(id));
      state.selectedMediaObjectsIDs = [...Array.from(newMediaObjectIDsSet)];
    },
    unSelectAllSelectedMediaObjectIDs: (state) => {
      state.selectedMediaObjectsIDs = [];
      state.lastSelectedMediaObjectID = null;
    },
    resetExplorationMediaObjectsSlice: () => initialState,
  },
  extraReducers: (builder) => {
    // fetchMediaObjectsData
    builder.addCase(
      fetchMediaObjectsData.pending,
      (state: explorationMediaObjectsStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      },
    );
    builder.addCase(
      fetchMediaObjectsData.fulfilled,
      (state: explorationMediaObjectsStateTypes, action) => {
        if (action?.meta?.arg?.reset) {
          state.data = [...action.payload?.data];
          state.hasMore = true;
          state.skip = 0;
        } else {
          // Check if any media object duplication found
          let foundDuplicate = false;
          let numberOfDuplicates = 0;
          _.map(action.payload.data, (i) => {
            if (_.find(state.data, (t) => t?.id === i?.id)) {
              foundDuplicate = true;
              numberOfDuplicates++;
            }
          });
          if (foundDuplicate) {
            alert(
              `Found ${numberOfDuplicates} duplicate objects! Please refresh the page. If this keeps happening, please contact us!`,
            );
          }
          state.data = [...state.data, ...action.payload?.data];
        }
        state.loading = false;
      },
    );
    builder.addCase(
      fetchMediaObjectsData.rejected,
      (state: explorationMediaObjectsStateTypes, action) => {
        state.loading = false;
        state.error.message = action.error.message || "No error provided";
      },
    );

    // fetchOneMediaObject
    builder.addCase(
      fetchOneMediaObject.fulfilled,
      (state: explorationMediaObjectsStateTypes, action) => {
        const newMediaObject = action.payload?.data;
        const mediaIndex = _.findIndex(
          state.data,
          (media) => media?.id === action.payload?.meta?.query?.mediaObjectId,
        );
        if (_.isNumber(mediaIndex)) {
          state.data[mediaIndex] = newMediaObject;
        }
        if (action.payload.meta?.options?.replaceCurrentMediaObject) {
          state.currentMediaObject = newMediaObject;
        }
      },
    );
    builder.addCase(
      fetchOneMediaObject.rejected,
      (state: explorationMediaObjectsStateTypes, action) => {
        state.error.message = action.error.message || "No error provided";
      },
    );
  },
});

export const {
  resetExplorationMediaObjectsSlice,
  updateMediaObjectsExplorationHasMore,
  updateMediaObjectsExplorationSkip,
  setMediaObjectExplorationCurrentMediaObject,
  addMediaObjectIDToSelectedMediaObjectIDs,
  removeMediaObjectIDFromSelectedMediaObjectIDs,
  addMediaObjectIDsToSelectedMediaObjectIDs,
  removeMediaObjectIDsFromSelectedMediaObjectIDs,
  unSelectAllSelectedMediaObjectIDs,
} = explorationMediaObjectsSlice.actions;
export default explorationMediaObjectsSlice.reducer;
