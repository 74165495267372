import _ from "lodash";
import { MediaObjectModel } from "models/exploration.model";
import {
  BoundingBox2DPainter,
  sourceTypeDetector,
} from "@quality-match/hermes";
import { BaseShape } from "@quality-match/shared-types";
import { useAppSelector } from "store/hooks";
import getAttributeFromAttributesMeta from "helpers/functions/getAttributeFromAttributesMeta";
import { GeometriesEnum } from "models/geometries.model";
import convertMediaObjectModelToBaseShape from "helpers/functions/hermes2dPainter/convertMediaObjectModelToBaseShape";
import { isAttributeAnnotationAttribute } from "helpers/functions/attributes/attributesHelpers";
import { useEffect, useState } from "react";
import {
  brightenHexColor,
  ColorValueHex,
  getGeometryColorBySource,
  transformString,
} from "components/utilFunctions";

interface Props {
  media_url: string;
  nextImageUrls?: string[];
  mediaObjectGeometries: MediaObjectModel[] | null;
  allGeometries: MediaObjectModel[] | null;
  baseShapeGeometries: BaseShape[];
  hoveredBBID?: string;
  setHoveredBBID?(newID: string): void;
  showLabel?: boolean;
  setShowLabel?: (showLabels: boolean) => void;
  onMediaObjectClick?: (clickedMediaObjectID: string) => void;
}

const HermesMediaViewer = ({
  media_url,
  nextImageUrls,
  mediaObjectGeometries,
  allGeometries,
  baseShapeGeometries = [],
  hoveredBBID,
  setHoveredBBID,
  showLabel = true,
  setShowLabel,
  onMediaObjectClick,
}: Props) => {
  const attributesMeta = useAppSelector(
    (state) => state.datasetSlice.attributes,
  );
  const detailsScreenSlice = useAppSelector(
    (state) => state.detailsScreenSlice,
  );

  const [isImageLoading, setIsImageLoading] = useState(false);

  useEffect(() => {
    setIsImageLoading(true);
  }, [media_url]);

  // Load the next images after the current image.
  // This is used to preload the next images to make the transition smoother.
  useEffect(() => {
    if (!nextImageUrls) return;

    const images = _.map(nextImageUrls, (url) => {
      const img = new Image();
      img.src = url;
      return img;
    });

    const handleImageLoad = () => {
      // Do nothing
    };

    _.map(images, (img) => {
      img.addEventListener("load", handleImageLoad);
    });

    // Cleanup the event listener
    return () => {
      _.map(images, (img) => {
        img.removeEventListener("load", handleImageLoad);
      });
    };
  }, [nextImageUrls]);

  // Convert geometries shape from MediaObjectModel to Hermes
  const geometriesPrepare = (): BaseShape[] => {
    let hermesGeometriesFormate: BaseShape[] = [];
    _.map(filterGeometries(), (geometry) => {
      let newGeometry = convertMediaObjectModelToBaseShape(geometry, {
        allGeometries,
        colorByClass: detailsScreenSlice?.geometries.color_by_class,
      });
      if (!_.isUndefined(newGeometry)) {
        newGeometry = changeColorOfHoveredGeometry(newGeometry);
        hermesGeometriesFormate = [...hermesGeometriesFormate, newGeometry];
      }
    });
    return hermesGeometriesFormate;
  };

  const changeColorOfHoveredGeometry = (geometry: BaseShape) => {
    const mediaObject = _.find(
      mediaObjectGeometries,
      (geometry) => geometry?.id === hoveredBBID,
    );
    if (hoveredBBID && hoveredBBID === geometry?.key && mediaObject) {
      return {
        ...geometry,
        settings: {
          ...geometry?.settings,
          color: brightenHexColor(
            getGeometryColorBySource(mediaObject) as ColorValueHex,
            70,
          ),

          strokeWidth: 3,
        },
      };
    }
    return geometry;
  };

  const filterGeometries = () => {
    let filteredGeometriesList: MediaObjectModel[] = [];
    _.map(mediaObjectGeometries, (geometry) => {
      // Reference
      if (geometry?.source === "REFERENCE") {
        const coordinates = geometry?.reference_data;
        switch (coordinates?.type) {
          case GeometriesEnum.Point2DAggregation:
          case GeometriesEnum.Point2D:
            if (detailsScreenSlice?.geometries.keypoints_reference === true) {
              filteredGeometriesList = [...filteredGeometriesList, geometry];
            }
            break;

          case GeometriesEnum.BoundingBox2D:
          case GeometriesEnum.BoundingBox2DAggregation:
            if (detailsScreenSlice?.geometries.bbs_reference) {
              filteredGeometriesList = [...filteredGeometriesList, geometry];
            }
            break;

          case GeometriesEnum.Polyline2DFlatCoordinates:
            if (detailsScreenSlice?.geometries.polylines_reference) {
              filteredGeometriesList = [...filteredGeometriesList, geometry];
            }
            break;
        }
      }
      // QM
      else if (geometry?.source === "QM") {
        const last_bb_index = geometry?.qm_data?.length - 1;
        const coordinates = geometry?.qm_data[last_bb_index];
        switch (coordinates?.type) {
          case GeometriesEnum.Point2DAggregation:
          case GeometriesEnum.Point2D:
            if (detailsScreenSlice?.geometries.keypoints_qm === true) {
              filteredGeometriesList = [...filteredGeometriesList, geometry];
            }
            break;

          case GeometriesEnum.BoundingBox2D:
          case GeometriesEnum.BoundingBox2DAggregation:
            if (detailsScreenSlice?.geometries.bbs_qm) {
              filteredGeometriesList = [...filteredGeometriesList, geometry];
            }
            break;

          case GeometriesEnum.Polyline2DFlatCoordinates:
            if (detailsScreenSlice?.geometries.polylines_qm) {
              filteredGeometriesList = [...filteredGeometriesList, geometry];
            }
            break;
        }
      }
    });
    return filteredGeometriesList;
  };

  const renderLabel = () => {
    const mediaObject = _.find(
      mediaObjectGeometries,
      (geometry) => geometry?.id === hoveredBBID,
    );
    const numberOfAttributes = _.size(
      _.filter(mediaObject?.attributes, (att) => {
        const attributeMeta = getAttributeFromAttributesMeta(
          attributesMeta,
          att?.metadata_id,
        );
        if (!attributeMeta) return false;
        return isAttributeAnnotationAttribute(attributeMeta?.attribute_group);
      }),
    );

    if (!mediaObject) return <></>;

    return (
      <div
        className={`w-[226px] px-2 py-1 rounded bg-white opacity-90
        border-4 border-white text-sm ${mediaObject?.color}`}
        onClick={(e) => e.stopPropagation()}
        onWheel={(e) => e.stopPropagation()}
      >
        <div>Source: {transformString(mediaObject?.source.toString())} </div>
        <div>Class: {mediaObject?.object_category_name || ""} </div>
        <div>Attributes: {numberOfAttributes}</div>
      </div>
    );
  };

  const selectShapesToDisplay = () => {
    if (isImageLoading) return [];
    if (detailsScreenSlice?.geometries.hide_all === true) return [];

    return [...geometriesPrepare(), ...baseShapeGeometries];
  };

  return (
    <div className="h-full w-auto bg-paletteGray-2">
      {
        <BoundingBox2DPainter
          mediaEventHandlers={{
            onReady: () => {
              setIsImageLoading(false);
              setHoveredBBID && setHoveredBBID(Math.random().toString());
            },
          }}
          displayerType="preview"
          src={media_url}
          mediaType={sourceTypeDetector(media_url)}
          defaultShapes={selectShapesToDisplay()}
          maxShapes={9999}
          showLabels={true}
          externalComponentProps={{
            component: showLabel ? renderLabel() : <></>,
            position: "bottom",
          }}
          scaleKeypointSize={1}
          zoomSettings={{
            zoomMultiplier: 1.1,
            zoomStep: 40,
          }}
          videoPlayerSettings={{
            url: media_url,
            controls: true,
            loop: true,
            progressInterval: 1000,
            width: "100%",
            height: "100%",
            muted: true,
            light: false,
            playing: true,
            onPlay: () => {
              // do nothing
            },
            onPause: () => {
              // do nothing
            },
            onProgress: () => {
              // do nothing
            },
            onStart: () => {
              // do nothing
            },
            onReady: () => {
              // do nothing
            },
            onError: () => {
              // do nothing
            },
            onDuration: () => {
              // do nothing
            },
          }}
          boundingBoxProps={{
            opacity: 0,
          }}
          previewModeOverrides={{
            // overrideSelectedShape: _.findIndex(geometries, { id: hoveredBBID }),
            mouseEnterShape: (shape?: BaseShape) => {
              if (
                setHoveredBBID &&
                !_.isUndefined(shape?.key) &&
                _.isString(shape?.key)
              ) {
                setShowLabel && setShowLabel(true);
                setHoveredBBID(shape?.key as string);
              }
            },
            mouseLeaveShape: () => {
              if (setHoveredBBID) {
                setShowLabel && setShowLabel(false);
                setHoveredBBID("");
              }
            },
            clickOnShape: (shape?: BaseShape) => {
              if (
                onMediaObjectClick &&
                !_.isUndefined(shape?.key) &&
                _.isString(shape?.key)
              ) {
                onMediaObjectClick(shape?.key as string);
              }
            },
          }}
          minZoomKeypoint={1}
          minZoomTimeout={1000}
          settings={{}}
        />
      }
    </div>
  );
};
export default HermesMediaViewer;
