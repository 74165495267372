import _ from "lodash";
import { VisualisationConfigurationModel } from "models/configurations.model";
import { MediaObjectModel } from "models/exploration.model";
import getCropVisualisationConfigID from "helpers/functions/hermes2dPainter/getCropVisualisationConfigID";
import {
  getAnnotatableUrl,
  getVisualisationUrl,
} from "components/utilFunctions";

/**
 * Get the URL of the media object based on if it has visualisations or not
 *  If it has visualisations, get the last visualisation URL
 *  If it doesn't have visualisations, get the crop URL
 * @param mediaObject The media object to get the URL from
 * @param visualisationConfigurations The visualisation configurations to get the subset ID from
 * @param subsetId The subset ID to get the visualisation configuration ID from
 * @returns The URL of the media object
 */
const selectMediaObjectURL = (
  mediaObject: MediaObjectModel,
  visualisationConfigurations: VisualisationConfigurationModel[] | null,
  subsetId: string,
): string => {
  // Keep using crop_url for now as well for backwards compatibility
  let objectUrl = mediaObject?.crop_url;
  if (mediaObject?.visualisations && mediaObject?.visualisations?.length > 0) {
    // If the visualisation has a subset_id, use that instead
    if (visualisationConfigurations !== null) {
      // Find the visualisation configuration ID for the subset
      const configID = getCropVisualisationConfigID(
        visualisationConfigurations,
        subsetId,
      );

      // If the visualisation configuration ID is found, use that instead
      if (configID !== null) {
        const visualisationForSubset = _.find(
          mediaObject?.visualisations,
          (visualisation) => {
            return visualisation?.visualisation_configuration_id === configID;
          },
        );

        objectUrl = visualisationForSubset?.media_url
          ? getVisualisationUrl(visualisationForSubset)
          : getAnnotatableUrl(mediaObject);
      }
    }
  }

  return objectUrl;
};

export default selectMediaObjectURL;
