interface Props {
  x: number;
  y: number;
  size?: number;
  gap?: number;
  color?: string;
}

const KeyPointSVG = ({
  x,
  y,
  size = 32,
  gap = 10,
  color = "#FB48C4",
}: Props) => {
  return (
    <>
      <line
        x1={x - size / 2}
        y1={y}
        x2={x - gap / 2}
        y2={y}
        stroke={color}
        strokeWidth="2"
      />
      <line
        x1={x + gap / 2}
        y1={y}
        x2={x + size / 2}
        y2={y}
        stroke={color}
        strokeWidth="2"
      />
      <line
        x1={x}
        y1={y - size / 2}
        x2={x}
        y2={y - gap / 2}
        stroke={color}
        strokeWidth="2"
      />
      <line
        x1={x}
        y1={y + gap / 2}
        x2={x}
        y2={y + size / 2}
        stroke={color}
        strokeWidth="2"
      />
    </>
  );
};

export default KeyPointSVG;
