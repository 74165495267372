interface Props {
  geometry: any;
  color: string;
  strokeWidth: number;
}

const Polyline2DFlatCoordinatesSVG = ({
  geometry,
  color,
  strokeWidth = 2,
}: Props) => {
  const points = [];
  for (let i = 0; i < geometry.coordinates.length; i += 2) {
    points.push(`${geometry.coordinates[i]},${geometry.coordinates[i + 1]}`);
  }
  return (
    <polyline
      points={points.join(" ")}
      stroke={color}
      strokeWidth={strokeWidth}
      fill="none"
      {...(geometry.closed ? { closed: "true" } : {})}
    />
  );
};

export default Polyline2DFlatCoordinatesSVG;
