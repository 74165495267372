import { MediaObjectRawModel } from "models/exploration.model";
import { useState } from "react";
import { GeometriesEnum, GeometryTypes } from "models/geometries.model";
import KeyPointSVG from "components/GeometryOverlays/Geometries/KeyPointSVG";
import BoundingBox2DCenterPointSVG from "components/GeometryOverlays/Geometries/BoundingBox2DCenterPointSVG";
import Polyline2DFlatCoordinatesSVG from "components/GeometryOverlays/Geometries/Polyline2DFlatCoordinatesSVG";
import { fitTypeEnum, getAnnotatableUrl } from "components/utilFunctions";

interface Props {
  mediaObject: MediaObjectRawModel;
  paddingPercent?: number;
  paddingMinimum?: number;
  aspectRatio?: [number, number];
  maxSize?: [number, number];
  color?: string;
  className?: string;
  onMouseOver?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const MediaObjectOverlay = ({
  mediaObject,
  paddingPercent = 40,
  paddingMinimum = 100,
  aspectRatio = [1, 1],
  maxSize = [450, 450],
  color = "#f854c4",
  className = "",
  onMouseOver,
  onClick,
}: Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const geometry = mediaObject?.qm_data?.length
    ? mediaObject.qm_data[mediaObject.qm_data.length - 1]
    : mediaObject?.reference_data;

  const calculateRatioModifiers = (
    itemWidth: number,
    itemHeight: number,
    targetRatio: [number, number],
  ): [number, number] => {
    const bbXRatio = itemWidth > itemHeight ? itemWidth / itemHeight : 1;
    const bbYRatio = itemHeight > itemWidth ? itemHeight / itemWidth : 1;

    const xTargetRatio =
      targetRatio[0] > targetRatio[1] ? targetRatio[0] / targetRatio[1] : 1;
    const yTargetRatio =
      targetRatio[1] > targetRatio[0] ? targetRatio[1] / targetRatio[0] : 1;

    const xRatioModifier = xTargetRatio / bbXRatio;
    const yRatioModifier = yTargetRatio / bbYRatio;

    const xNormalizedModifier =
      xRatioModifier > yRatioModifier ? xRatioModifier / yRatioModifier : 1;
    const yNormalizedModifier =
      yRatioModifier > xRatioModifier ? yRatioModifier / xRatioModifier : 1;

    return [xNormalizedModifier, yNormalizedModifier];
  };

  const getBoundingBox = (geometry: GeometryTypes) => {
    switch (geometry.type) {
      case GeometriesEnum.Point2D:
      case GeometriesEnum.Point2DAggregation:
        return {
          x1: geometry.x,
          y1: geometry.y,
          x2: geometry.x,
          y2: geometry.y,
          width: 0,
          height: 0,
          centerX: geometry.x,
          centerY: geometry.y,
        };

      case GeometriesEnum.BoundingBox2D:
      case GeometriesEnum.BoundingBox2DAggregation: {
        const halfWidth = geometry.width / 2;
        const halfHeight = geometry.height / 2;
        return {
          x1: geometry.x - halfWidth,
          y1: geometry.y - halfHeight,
          x2: geometry.x + halfWidth,
          y2: geometry.y + halfHeight,
          width: geometry.width,
          height: geometry.height,
          centerX: geometry.x,
          centerY: geometry.y,
        };
      }

      case GeometriesEnum.Polyline2DFlatCoordinates: {
        const xs = [];
        const ys = [];
        for (let i = 0; i < geometry.coordinates.length; i += 2) {
          xs.push(geometry.coordinates[i]);
          ys.push(geometry.coordinates[i + 1]);
        }
        const minX = Math.min(...xs);
        const maxX = Math.max(...xs);
        const minY = Math.min(...ys);
        const maxY = Math.max(...ys);
        const width = maxX - minX;
        const height = maxY - minY;
        return {
          x1: minX,
          y1: minY,
          x2: maxX,
          y2: maxY,
          width,
          height,
          centerX: minX + width / 2,
          centerY: minY + height / 2,
        };
      }
    }
  };

  const calculatePadding = (
    imageWidth: number,
    imageHeight: number,
    bbox: ReturnType<typeof getBoundingBox>,
  ) => {
    if (!bbox) return { paddedWidth: 0, paddedHeight: 0 };

    // For points, use minimum padding
    if (bbox.width === 0 && bbox.height === 0) {
      return {
        paddedWidth: paddingMinimum * 2,
        paddedHeight: paddingMinimum * 2,
      };
    }

    // Calculate padding based on percentage and minimum
    const xPadding = Math.max(
      Math.ceil(((paddingPercent / 100) * bbox.width) / 2),
      paddingMinimum,
    );
    const yPadding = Math.max(
      Math.ceil(((paddingPercent / 100) * bbox.height) / 2),
      paddingMinimum,
    );
    // Calculate padded dimensions
    let paddedWidth = xPadding * 2 + bbox.width;
    let paddedHeight = yPadding * 2 + bbox.height;

    // Ensure padding doesn't exceed image bounds
    paddedWidth = Math.min(paddedWidth, imageWidth);
    paddedHeight = Math.min(paddedHeight, imageHeight);

    return { paddedWidth, paddedHeight };
  };

  const getCropCoordinates = () => {
    if (!geometry) return null;

    // Get original image dimensions
    const originalWidth =
      mediaObject.visualisations?.[0]?.parameters?.original_image_width ??
      Infinity;
    const originalHeight =
      mediaObject.visualisations?.[0]?.parameters?.original_image_height ??
      Infinity;

    // Get bounding box
    const bbox = getBoundingBox(geometry);
    if (!bbox) return null;

    // Calculate padding
    const { paddedWidth, paddedHeight } = calculatePadding(
      originalWidth,
      originalHeight,
      bbox,
    );
    // Calculate aspect ratio modifiers
    const [xRatioMod, yRatioMod] = calculateRatioModifiers(
      paddedWidth,
      paddedHeight,
      maxSize || aspectRatio,
    );

    // Calculate initial crop box
    let x1 = Math.round(bbox.centerX - (paddedWidth / 2) * xRatioMod);
    let x2 = Math.round(bbox.centerX + (paddedWidth / 2) * xRatioMod);
    let y1 = Math.round(bbox.centerY - (paddedHeight / 2) * yRatioMod);
    let y2 = Math.round(bbox.centerY + (paddedHeight / 2) * yRatioMod);

    // Ensure crop box stays within image bounds
    if (x1 < 0) {
      x1 = 0;
      x2 = Math.ceil(paddedWidth * xRatioMod);
      if (x2 > originalWidth) x2 = originalWidth;
    }
    if (x2 > originalWidth) {
      x2 = originalWidth;
      x1 = originalWidth - Math.ceil(paddedWidth * xRatioMod);
      if (x1 < 0) x1 = 0;
    }
    if (y1 < 0) {
      y1 = 0;
      y2 = Math.ceil(paddedHeight * yRatioMod);
      if (y2 > originalHeight) y2 = originalHeight;
    }

    if (y2 > originalHeight) {
      y2 = originalHeight;
      y1 = originalHeight - Math.ceil(paddedHeight * yRatioMod);
      if (y1 < 0) y1 = 0;
    }

    return { x1, y1, x2, y2 };
  };

  const cropCoords = getCropCoordinates() || { x1: 0, y1: 0, x2: 0, y2: 0 };

  const renderGeometry = () => {
    if (!geometry || !imageLoaded) return null;

    switch (geometry.type) {
      case GeometriesEnum.Point2D:
      case GeometriesEnum.Point2DAggregation:
        return <KeyPointSVG x={geometry.x} y={geometry.y} color={color} />;
      case GeometriesEnum.BoundingBox2D:
      case GeometriesEnum.BoundingBox2DAggregation: {
        return (
          <BoundingBox2DCenterPointSVG
            x={geometry.x}
            y={geometry.y}
            width={geometry.width}
            height={geometry.height}
            color={color}
            strokeWidth={
              Math.max(
                cropCoords?.x2 - cropCoords?.x1,
                cropCoords.y2 - cropCoords.y1,
              ) / 100
            }
          />
        );
      }
      case GeometriesEnum.Polyline2DFlatCoordinates: {
        return (
          <Polyline2DFlatCoordinatesSVG
            geometry={geometry}
            color={color}
            strokeWidth={
              Math.max(
                cropCoords?.x2 - cropCoords?.x1,
                cropCoords.y2 - cropCoords.y1,
              ) / 100
            }
          />
        );
      }
    }
  };

  const getCropViewBox = () => {
    if (!cropCoords) return "0 0 100 100";
    const { x1, y1, x2, y2 } = cropCoords;
    return `${x1} ${y1} ${x2 - x1} ${y2 - y1}`;
  };

  return (
    <div
      className="relative w-full cursor-pointer"
      onMouseOver={onMouseOver}
      onClick={onClick}
    >
      <img
        src={getAnnotatableUrl(
          mediaObject,
          maxSize[0],
          maxSize[1],
          fitTypeEnum.CUT_TO_FIT,
          [
            [cropCoords.x1, cropCoords.y1],
            [cropCoords.x2, cropCoords.y2],
          ],
        )}
        alt={mediaObject?.id}
        className={`w-full ${className}`}
        onLoad={() => setImageLoaded(true)}
      />
      {imageLoaded && (
        <svg
          className="absolute top-0 left-0 w-full h-full"
          viewBox={getCropViewBox()}
          preserveAspectRatio="none"
        >
          {renderGeometry()}
        </svg>
      )}
    </div>
  );
};

export default MediaObjectOverlay;
