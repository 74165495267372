import _ from "lodash";

/**
 * A function that checks if a URL is still valid.
 * @param url The URL to check if it is still valid
 * @returns If the URL is still valid, returns true. If the URL is expired, returns false. If the URL is invalid, returns null.
 */
const isURLStillValid = (url: string) => {
  const isAWSValid = isAWSLinkValid(url);
  if (_.isBoolean(isAWSValid)) {
    return isAWSValid;
  }

  const isQmCDNValid = isQmCDNLinkValid(url);
  if (_.isBoolean(isQmCDNValid)) {
    return isQmCDNValid;
  }

  console.error("isURLStillValid: URL structure is invalid!");
  return null;
};

/**
 * A function that checks if an AWS S3 URL is still valid.
 * @param url The AWS S3 URL to check if it is still valid
 * @returns If the URL is still valid, returns true. If the URL is expired, returns false. If the URL is invalid, returns null.
 */
function isAWSLinkValid(url: string): boolean | null {
  const expirationPattern = /X-Amz-Expires=(\d+)/;
  const amzDatePattern = /X-Amz-Date=(\d{8}T\d{6}Z)/;

  const expirationMatch = url.match(expirationPattern);
  const amzDateMatch = url.match(amzDatePattern);

  if (expirationMatch && amzDateMatch) {
    const expirationSeconds = parseInt(expirationMatch[1]);
    const amzDateString = amzDateMatch[1];

    const year = parseInt(amzDateString.substring(0, 4));
    const month = parseInt(amzDateString.substring(4, 6));
    const day = parseInt(amzDateString.substring(6, 8));
    const hour = parseInt(amzDateString.substring(9, 11));
    const minute = parseInt(amzDateString.substring(11, 13));
    const second = parseInt(amzDateString.substring(13, 15));

    const amzDate = new Date(
      Date.UTC(year, month - 1, day, hour, minute, second),
    );
    const expirationDate = new Date(
      amzDate.getTime() + expirationSeconds * 1000,
    );
    const currentTime = new Date();

    return currentTime <= expirationDate;
  }

  return null; // Expiration or Amz-Date not found
}

function isQmCDNLinkValid(url: string): boolean | null {
  // Parse the URL
  const parsedUrl = new URL(url);

  // Extract the 'Expires' parameter and convert it to a number
  const expiryTimestamp = Number(parsedUrl.searchParams.get("Expires"));

  if (isNaN(expiryTimestamp)) {
    return null; // Expiry timestamp not found
  }

  // Get the current timestamp
  const currentTimestamp = Math.floor(Date.now() / 1000);

  // Check if the expiry timestamp is greater than the current timestamp
  return currentTimestamp <= expiryTimestamp;
}

export default isURLStillValid;
